import type { KLineData, StockInfo } from './stockData'
import SHA256 from 'crypto-js/sha256'

interface ApiStockData {
  交易日期: number
  开盘价: number
  收盘价: number
  最高价: number
  最低价: number
  '成交量(手)': number
  股票代码: string
  股票简称: string
  股票名称: string
  所属行业: string
  涨跌幅: number
  涨跌额: number
  '换手率(%)': number
  '振幅(%)': number
}

interface ApiResponse {
  collection_name: string
  data: ApiStockData[]
}

export interface StockDataResponse {
  klineData: KLineData[]
  stockInfo: StockInfo
}

async function generateSignature(message: string): Promise<string> {
  try {
    return SHA256(message).toString()
  } catch (error) {
    console.error('生成签名失败:', error)
    throw error
  }
}

export async function fetchStockData(): Promise<StockDataResponse> {
  try {
    // 生成时间戳和签名
    const secretKey = 'pfgeKMXU0286'
    const timestamp = Math.floor(Date.now() / 1000).toString()
    const message = `${secretKey}${timestamp}`
    const signature = await generateSignature(message)

    const response = await fetch('/api/random_data', {
      headers: {
        'X-Timestamp': timestamp,
        'X-Sign': signature,
        'Content-Type': 'application/json',
      },
    })
    const apiData: ApiResponse = await response.json()

    const klineData = apiData.data.map((item) => ({
      time: item.交易日期,
      open: item.开盘价,
      close: item.收盘价,
      high: item.最高价,
      low: item.最低价,
      volume: item['成交量(手)'] * 100, // 转换为股
      交易日期: item.交易日期.toString(),
      changePercent: item.涨跌幅, // 直接使用后端提供的涨跌幅
      changeAmount: item.涨跌额, // 直接使用后端提供的涨跌额
      turnoverRate: item['换手率(%)'], // 直接使用后端提供的换手率
      amplitude: item['振幅(%)'], // 直接使用后端提供的振幅
    }))

    const stockInfo: StockInfo = {
      name: apiData.data[0].股票简称 || '',
      code: apiData.data[0].股票代码 || '',
      fullName: apiData.data[0].股票名称 || '',
      industry: apiData.data[0].所属行业 || '',
    }

    return {
      klineData,
      stockInfo,
    }
  } catch (error) {
    console.error('获取股票数据失败:', error)
    return {
      klineData: [],
      stockInfo: {
        name: '',
        code: '',
        fullName: '',
        industry: '',
      },
    }
  }
}

// 添加用户训练数据的接口
export interface TrainingResult {
  userId?: string // 用户ID（可选）
  stockCode: string // 股票代码
  stockName: string // 股票名称
  dateRange: string // 训练日期范围
  finalProfit: number // 最终收益
  finalProfitRate: number // 最终收益率
  stockRangeProfit: number // 股票区间涨跌幅
  tradeHistory: {
    // 交易历史
    type: 'buy' | 'sell' // 交易类型
    time: string // 交易时间（第几根K线）
    price: number // 交易价格
    amount: number // 交易数量
    total: number // 交易总额
  }[]
  startTime: string // 开始训练时间
  endTime: string // 结束训练时间
  duration: number // 训练持续时间（秒）
}

// 发送训练结果到后端
export async function sendTrainingResult(result: TrainingResult): Promise<boolean> {
  try {
    // 本地模式：直接返回成功，不发送数据到后端
    // console.log('训练结果已保存（本地模式）')
    console.log('训练结果已保存')
    return true
  } catch (error) {
    console.error('保存训练结果失败:', error)
    return false
  }
}
