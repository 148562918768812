interface ThemeColors {
  backgroundColor: string
  textColor: string
  gridColor: string
  axisLineColor: string
  splitLineColor: string
  watermarkColor: string
  tooltipBackground: string
  tooltipBorderColor: string
  secondaryTextColor: string
  upColor: string
  downColor: string
  ma5Color: string
  ma10Color: string
  ma30Color: string
  ma120Color: string
  costLineColor: string
  zoomFillColor: string
  zoomHandleColor: string
  macdColor: string
  macdUpColor: string
  macdDownColor: string
}

export const lightTheme: ThemeColors = {
  backgroundColor: '#fff',
  textColor: '#333',
  gridColor: '#eee',
  axisLineColor: '#ccc',
  splitLineColor: '#eee',
  watermarkColor: 'rgba(0, 0, 0, 0.05)',
  tooltipBackground: '#fff',
  tooltipBorderColor: '#ddd',
  secondaryTextColor: '#666',
  upColor: 'rgb(214, 10, 34)',
  downColor: 'rgb(3, 123, 102)',
  ma5Color: '#fdb922',
  ma10Color: '#da6ee8',
  ma30Color: '#39afe6',
  ma120Color: '#8dc556',
  costLineColor: '#ff9800',
  zoomFillColor: 'rgba(144, 147, 153, 0.4)',
  zoomHandleColor: '#A7B1C3',
  macdColor: '#9b59b6',
  macdUpColor: 'rgb(214, 10, 34)',
  macdDownColor: 'rgb(3, 123, 102)',
}

export const darkTheme: ThemeColors = {
  backgroundColor: '#1a1a1a',
  textColor: '#e0e0e0',
  gridColor: '#404040',
  axisLineColor: '#404040',
  splitLineColor: '#2d2d2d',
  watermarkColor: 'rgba(255, 255, 255, 0.08)',
  tooltipBackground: '#2d2d2d',
  tooltipBorderColor: '#404040',
  secondaryTextColor: '#a0a0a0',
  upColor: 'rgb(214, 10, 34)',
  downColor: 'rgb(3, 123, 102)',
  ma5Color: '#fdb922',
  ma10Color: '#da6ee8',
  ma30Color: '#39afe6',
  ma120Color: '#8dc556',
  costLineColor: '#ff9800',
  zoomFillColor: 'rgba(144, 147, 153, 0.2)',
  zoomHandleColor: '#909399',
  macdColor: '#9b59b6',
  macdUpColor: 'rgb(214, 10, 34)',
  macdDownColor: 'rgb(3, 123, 102)',
}

export const getThemeColors = (): ThemeColors => {
  const isDarkMode = document.documentElement.getAttribute('data-theme') === 'dark'
  return isDarkMode ? darkTheme : lightTheme
}
