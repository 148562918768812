<template>
  <div class="stock-training">
    <Loading :visible="isLoading" text="正在加载股票数据..." />
    <div class="main-layout">
      <div class="content-section">
        <!-- 左侧K线图 -->
        <div class="left-section">
          <div class="chart-container">
            <div v-if="isLoading" class="loading-container">
              <div class="loading-spinner"></div>
              <div class="loading-text">数据加载中...</div>
            </div>
            <KLineChart
              v-else
              :data="stockData"
              :current-index="currentIndex"
              :show-real-time="false"
              :trade-markers="tradeMarkers"
              :cost-price="costBasis"
            />
          </div>
        </div>

        <!-- 右侧信息面板 -->
        <div class="right-section">
          <!-- 股票信息 -->
          <div class="header">
            <div class="stock-info">
              <h2>模拟股票</h2>
              <div class="price-info" v-if="!isLoading">
                <span :class="{ up: currentPrice > prevPrice, down: currentPrice < prevPrice }">
                  {{ currentPrice.toFixed(2) }}
                </span>
                <span class="change" :class="{ up: priceChange > 0, down: priceChange < 0 }">
                  {{ priceChange.toFixed(2) }}%
                </span>
              </div>
              <div class="price-info" v-else>
                <span>加载中...</span>
              </div>
            </div>
          </div>

          <!-- 账户信息 -->
          <div class="account-info">
            <div class="info-item">
              <span class="label">可用资金</span>
              <span class="value" v-if="!isLoading">¥{{ availableFunds.toFixed(2) }}</span>
              <span class="value loading" v-else>加载中...</span>
            </div>
            <div class="info-item">
              <span class="label">持仓数量</span>
              <span class="value" v-if="!isLoading">{{ holdings }}</span>
              <span class="value loading" v-else>加载中...</span>
            </div>
            <div class="info-item">
              <span class="label">持仓市值</span>
              <span class="value" v-if="!isLoading"
                >¥{{ (holdings * currentPrice).toFixed(2) }}</span
              >
              <span class="value loading" v-else>加载中...</span>
            </div>
            <div class="info-item">
              <span class="label">持仓成本</span>
              <span class="value" v-if="!isLoading">¥{{ costBasis.toFixed(2) }}</span>
              <span class="value loading" v-else>加载中...</span>
            </div>
            <div class="info-item">
              <span class="label">总资产</span>
              <span class="value" v-if="!isLoading">¥{{ totalAssets.toFixed(2) }}</span>
              <span class="value loading" v-else>加载中...</span>
            </div>
            <div class="info-item">
              <span class="label">持仓收益</span>
              <template v-if="!isLoading">
                <span class="value" :class="{ up: holdingsProfit > 0, down: holdingsProfit < 0 }">
                  ¥{{ holdingsProfit.toFixed(2) }}
                </span>
                <span
                  class="profit-rate"
                  :class="{ up: holdingsProfitRate > 0, down: holdingsProfitRate < 0 }"
                >
                  {{ holdingsProfitRate.toFixed(2) }}%
                </span>
              </template>
              <span class="value loading" v-else>加载中...</span>
            </div>
          </div>

          <!-- 交易面板 -->
          <div class="trading-panel">
            <!-- <h3>交易面板</h3> -->
            <div class="input-group">
              <div class="input-wrapper">
                <label>交易手数（100的整数倍）</label>
                <input
                  type="number"
                  v-model="tradeAmount"
                  placeholder="请输入交易数量"
                  min="100"
                  :max="maxTradeAmount"
                  step="100"
                  :disabled="isLoading"
                />
                <span class="max-hint">最大可买入: {{ maxBuyAmount }}</span>
              </div>
              <div class="button-group">
                <button @click="buy" :disabled="!canBuy || isLoading" class="buy-btn">
                  <span>买入</span>
                  <!-- <small>￥{{ (tradeAmount * currentPrice).toFixed(2) }}</small> -->
                </button>
                <button @click="sell" :disabled="!canSell || isLoading" class="sell-btn">
                  <span>卖出</span>
                  <!-- <small>￥{{ (tradeAmount * currentPrice).toFixed(2) }}</small> -->
                </button>
              </div>
              <div class="quick-actions">
                <button
                  @click="buyAll"
                  :disabled="isLoading || maxBuyAmount === 0 || isTrainingEnd"
                  class="quick-btn buy-all"
                >
                  一键满仓
                </button>
                <button
                  @click="sellAll"
                  :disabled="isLoading || holdings === 0 || isTrainingEnd"
                  class="quick-btn sell-all"
                >
                  一键空仓
                </button>
              </div>
            </div>
          </div>

          <!-- 进度控制 -->
          <div class="progress-panel">
            <div class="progress-info">
              <div class="progress-text">
                <span>训练进度</span>
                <span>{{ currentIndex - 300 }}/200</span>
              </div>
              <div class="progress-bar">
                <div
                  class="progress-fill"
                  :style="{ width: ((currentIndex - 300) / 200) * 100 + '%' }"
                ></div>
              </div>
            </div>
            <div class="button-group">
              <button
                @mousedown="startAutoStep"
                @mouseup="stopAutoStep"
                @mouseleave="stopAutoStep"
                @click="isTrainingEnd ? (showSummary = true) : nextStep()"
                :disabled="isLoading"
                class="next-btn"
              >
                {{ isTrainingEnd ? '查看报告' : '下一步' }}
              </button>
              <button
                @click="changeStock"
                :disabled="isLoading || !canChangeStock"
                class="change-stock"
              >
                换股
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 交易记录 -->
      <div class="trade-history">
        <h3>
          <span>交易记录</span>
          <span class="summary">共 {{ tradeHistory.length }} 笔交易</span>
        </h3>
        <div class="history-list">
          <div v-if="isLoading" class="loading-container">
            <div class="loading-spinner"></div>
            <div class="loading-text">数据加载中...</div>
          </div>
          <template v-else>
            <div
              v-for="(record, index) in tradeHistory"
              :key="index"
              class="history-item"
              :class="record.type"
            >
              <div class="history-header">
                <span class="trade-type">{{ record.type === 'buy' ? '买入' : '卖出' }}</span>
                <span class="trade-time">{{ record.time }}</span>
              </div>
              <div class="history-details">
                <div class="detail-item">
                  <span class="label">成交价</span>
                  <span class="value">￥{{ record.price.toFixed(2) }}</span>
                </div>
                <div class="detail-item">
                  <span class="label">成交量</span>
                  <span class="value">{{ record.amount }}股</span>
                </div>
                <div class="detail-item">
                  <span class="label">成交额</span>
                  <span class="value">￥{{ record.total.toFixed(2) }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <TrainingSummary
      :visible="showSummary"
      :stock-info="currentStock"
      :date-range="dateRange"
      :final-profit-rate="finalProfitRate"
      :final-profit="finalProfit"
      :trade-count="tradeHistory.length"
      :stock-range-profit="stockRangeProfit"
      @restart="restart"
      @close="showSummary = false"
    />
    <!-- 添加浮动按钮和二维码 -->
    <div class="float-button" @mouseenter="showQRCode = true" @mouseleave="showQRCode = false">
      <CommentOutlined class="icon" />
      <div class="qr-popup" v-show="showQRCode">
        <img src="../image/service_man.jpg" alt="客服二维码" />
      </div>
    </div>
    <!-- 添加打赏按钮 -->
    <div
      class="float-button reward"
      @mouseenter="showRewardQR = true"
      @mouseleave="showRewardQR = false"
    >
      <DollarCircleOutlined class="icon" />
      <div class="reward-popup" v-show="showRewardQR">
        <img src="../image/wechat_pay.jpg" alt="打赏二维码" />
        <div class="reward-text">免费不易，求打赏</div>
      </div>
    </div>
    <!-- 添加主题切换按钮 -->
    <div class="float-button theme-toggle" @click="toggleTheme" title="切换主题">
      <BulbOutlined class="icon" :class="{ dark: isDarkMode }" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue'
import KLineChart from '../components/KLineChart.vue'
import TrainingSummary from '../components/TrainingSummary.vue'
import Loading from '../components/Loading.vue'
import { CommentOutlined, BulbOutlined, DollarCircleOutlined } from '@ant-design/icons-vue'
import { generateStockInfo, type KLineData, type StockInfo } from '../utils/stockData'
import {
  fetchStockData,
  type StockDataResponse,
  sendTrainingResult,
  type TrainingResult,
} from '../utils/api'

const INITIAL_FUNDS = 100000
const TRAINING_START = 300
const TRAINING_END = 500

const stockData = ref<KLineData[]>([])
const currentStock = ref<StockInfo>({
  name: '模拟股票',
  code: '000000',
  fullName: '模拟股票',
  industry: '模拟行业',
})
const currentIndex = ref(TRAINING_START)
const availableFunds = ref(INITIAL_FUNDS)
const holdings = ref(0)
const tradeAmount = ref(500)
const costBasis = ref(0)
const realizedProfit = ref(0)
const totalBuyAmount = ref(0)
const totalSellAmount = ref(0)
const isLoading = ref(true)
const showSummary = ref(false)
const showQRCode = ref(false)
const showRewardQR = ref(false)
const isDarkMode = ref(false)

const currentPrice = computed(() => {
  if (!stockData.value.length) return 0
  if (currentIndex.value >= TRAINING_END) {
    return stockData.value[TRAINING_END - 1].close
  }
  return stockData.value[currentIndex.value].close
})

const prevPrice = computed(() => {
  if (!stockData.value.length) return currentPrice.value
  if (currentIndex.value >= TRAINING_END) {
    return stockData.value[TRAINING_END - 2].close
  }
  return stockData.value[currentIndex.value - 1].close
})

const priceChange = computed(() => {
  if (!stockData.value.length) return 0
  if (currentIndex.value >= TRAINING_END) {
    return stockData.value[TRAINING_END - 1].changePercent * 100
  }
  return stockData.value[currentIndex.value].changePercent * 100
})

const maxTradeAmount = computed(() => {
  if (holdings.value > 0) {
    return Math.floor(holdings.value / 100) * 100
  }
  return Math.floor(availableFunds.value / currentPrice.value / 100) * 100
})

const maxBuyAmount = computed(() => {
  return Math.floor(availableFunds.value / currentPrice.value / 100) * 100
})

const canNextStep = computed(() => {
  return currentIndex.value < TRAINING_END
})

const isTrainingEnd = computed(() => {
  return currentIndex.value >= TRAINING_END
})

const canBuy = computed(() => {
  return (
    !isTrainingEnd.value &&
    tradeAmount.value > 0 &&
    tradeAmount.value <= maxBuyAmount.value &&
    tradeAmount.value * currentPrice.value <= availableFunds.value &&
    tradeAmount.value % 100 === 0
  )
})

const canSell = computed(() => {
  return (
    !isTrainingEnd.value &&
    tradeAmount.value > 0 &&
    tradeAmount.value <= holdings.value &&
    tradeAmount.value % 100 === 0
  )
})

const holdingsProfit = computed(() => {
  // 如果没有持仓，持仓收益为0
  if (holdings.value === 0) {
    return 0
  }
  // 只计算未实现收益（当前持仓的浮动盈亏）
  return (currentPrice.value - costBasis.value) * holdings.value
})

const holdingsProfitRate = computed(() => {
  // 如果没有持仓，收益率为0
  if (holdings.value === 0) {
    return 0
  }
  // 计算当前持仓的收益率：未实现收益/持仓成本*100
  const holdingsCost = costBasis.value * holdings.value
  return (holdingsProfit.value / holdingsCost) * 100
})

const totalAssets = computed(() => {
  // 总资产 = 可用资金 + 持仓市值
  return availableFunds.value + holdings.value * currentPrice.value
})

interface TradeRecord {
  type: 'buy' | 'sell'
  time: string
  price: number
  amount: number
  total: number
}

const tradeHistory = ref<TradeRecord[]>([])

interface TradeMarker {
  time: number
  type: 'buy' | 'sell'
  price: number
  amount: number
}

const tradeMarkers = ref<TradeMarker[]>([])

const formatTime = (date: Date): string => {
  const pad = (n: number) => n.toString().padStart(2, '0')
  return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
}

const addTradeRecord = (type: 'buy' | 'sell', amount: number, price: number) => {
  const record: TradeRecord = {
    type,
    time: `${500 - currentIndex.value}`,
    price,
    amount,
    total: amount * price,
  }
  tradeHistory.value.unshift(record)

  // 添加交易标记
  tradeMarkers.value.push({
    time: currentIndex.value,
    type,
    price,
    amount,
  })
}

const buy = () => {
  if (!canBuy.value) return
  const cost = tradeAmount.value * currentPrice.value
  availableFunds.value -= cost
  holdings.value += tradeAmount.value
  totalBuyAmount.value += cost
  costBasis.value = (totalBuyAmount.value - totalSellAmount.value) / holdings.value
  addTradeRecord('buy', tradeAmount.value, currentPrice.value)
}

const sell = () => {
  if (!canSell.value) return
  const revenue = tradeAmount.value * currentPrice.value
  availableFunds.value += revenue

  // 计算本次卖出的实现收益
  const sellProfit = (currentPrice.value - costBasis.value) * tradeAmount.value
  realizedProfit.value += sellProfit

  holdings.value -= tradeAmount.value
  totalSellAmount.value += revenue

  // 更新成本基础
  if (holdings.value === 0) {
    costBasis.value = 0
    totalBuyAmount.value = 0 // 清空累计买入金额
    totalSellAmount.value = 0 // 清空累计卖出金额
  } else {
    costBasis.value = (totalBuyAmount.value - totalSellAmount.value) / holdings.value
  }

  addTradeRecord('sell', tradeAmount.value, currentPrice.value)
}

const stockRangeProfit = computed(() => {
  if (!stockData.value.length) return 0
  const startPrice = stockData.value[TRAINING_START].close
  const endPrice = stockData.value[TRAINING_END - 1].close
  return ((endPrice - startPrice) / startPrice) * 100
})

const dateRange = computed(() => {
  if (!stockData.value.length) return ''
  const startDate = stockData.value[TRAINING_START].交易日期
  const endDate = stockData.value[TRAINING_END - 1].交易日期
  return `${startDate} ~ ${endDate}`
})

const trainingStartTime = ref<Date | null>(null)

const restart = async () => {
  try {
    isLoading.value = true
    showSummary.value = false
    // 重置所有状态
    currentIndex.value = TRAINING_START
    holdings.value = 0
    availableFunds.value = INITIAL_FUNDS
    realizedProfit.value = 0
    costBasis.value = 0
    tradeHistory.value = []
    tradeMarkers.value = []
    totalBuyAmount.value = 0
    totalSellAmount.value = 0

    // 记录训练开始时间
    trainingStartTime.value = new Date()

    // 重新获取数据
    await initStockData()
  } finally {
    isLoading.value = false
  }
}

const handleTrainingEnd = async () => {
  if (!trainingStartTime.value) return

  const endTime = new Date()
  const duration = Math.floor((endTime.getTime() - trainingStartTime.value.getTime()) / 1000)

  const result: TrainingResult = {
    stockCode: currentStock.value.code,
    stockName: currentStock.value.name,
    dateRange: dateRange.value,
    finalProfit: realizedProfit.value,
    finalProfitRate: ((totalAssets.value - INITIAL_FUNDS) / INITIAL_FUNDS) * 100,
    stockRangeProfit: stockRangeProfit.value,
    tradeHistory: tradeHistory.value,
    startTime: trainingStartTime.value.toISOString(),
    endTime: endTime.toISOString(),
    duration: duration,
  }

  // 发送训练结果到后端
  try {
    await sendTrainingResult(result)
    console.log('训练结果已发送到后端')
  } catch (error) {
    console.error('发送训练结果失败:', error)
  }

  // 显示训练总结
  showSummary.value = true
}

const nextStep = () => {
  if (!canNextStep.value) return
  currentIndex.value++
  if (currentIndex.value >= TRAINING_END) {
    // 到达最后一天时，使用最后一天的收盘价计算最终收益
    const lastPrice = stockData.value[TRAINING_END - 1].close
    // 计算最后一天的未实现收益并转换为已实现收益
    if (holdings.value > 0) {
      const finalUnrealizedProfit = (lastPrice - costBasis.value) * holdings.value
      realizedProfit.value += finalUnrealizedProfit
      // 更新可用资金（相当于卖出所有持仓）
      availableFunds.value += holdings.value * lastPrice
      // 清空持仓相关数据
      holdings.value = 0
      costBasis.value = 0
      totalBuyAmount.value = 0
      totalSellAmount.value = 0
    }

    // 处理训练结束
    handleTrainingEnd()
  }
}

const canChangeStock = ref(true)
const changeStockTimer = ref<number | null>(null)

const changeStock = async () => {
  if (!canChangeStock.value) return

  try {
    canChangeStock.value = false
    isLoading.value = true
    showSummary.value = false
    // 重置所有状态
    currentIndex.value = TRAINING_START
    holdings.value = 0
    availableFunds.value = INITIAL_FUNDS
    realizedProfit.value = 0
    costBasis.value = 0
    tradeHistory.value = []
    tradeMarkers.value = [] // 清空交易标记
    totalBuyAmount.value = 0
    totalSellAmount.value = 0

    // 重新获取数据
    await initStockData()
  } finally {
    isLoading.value = false
    // 设置1秒后才能再次点击
    if (changeStockTimer.value) {
      clearTimeout(changeStockTimer.value)
    }
    changeStockTimer.value = window.setTimeout(() => {
      canChangeStock.value = true
    }, 1000)
  }
}

const initStockData = async () => {
  try {
    isLoading.value = true
    const response = await fetchStockData()
    stockData.value = response.klineData
    currentStock.value = response.stockInfo
  } catch (error) {
    console.error('初始化数据失败:', error)
  } finally {
    isLoading.value = false
  }
}

const startTime = computed(() => {
  if (!stockData.value.length) return 0
  return stockData.value[TRAINING_START].time
})

const endTime = computed(() => {
  if (!stockData.value.length) return 0
  return stockData.value[TRAINING_END - 1].time
})

const finalAssets = computed(() => {
  return availableFunds.value + holdings.value * currentPrice.value
})

const finalProfit = computed(() => {
  const realizedProfitValue = realizedProfit.value
  const unrealizedProfit = (currentPrice.value - costBasis.value) * holdings.value
  return realizedProfitValue + unrealizedProfit
})

const finalProfitRate = computed(() => {
  // 使用最终总资产与初始资金计算收益率
  return ((finalAssets.value - INITIAL_FUNDS) / INITIAL_FUNDS) * 100
})

const buyAll = () => {
  if (maxBuyAmount.value === 0 || isTrainingEnd.value) return
  // 直接使用 maxBuyAmount 执行买入，不修改 tradeAmount
  const tempAmount = maxBuyAmount.value
  const cost = tempAmount * currentPrice.value
  availableFunds.value -= cost
  holdings.value += tempAmount
  totalBuyAmount.value += cost
  costBasis.value = (totalBuyAmount.value - totalSellAmount.value) / holdings.value
  addTradeRecord('buy', tempAmount, currentPrice.value)
}

const sellAll = () => {
  if (holdings.value === 0 || isTrainingEnd.value) return
  // 直接使用 holdings 执行卖出，不修改 tradeAmount
  const tempAmount = holdings.value
  const revenue = tempAmount * currentPrice.value
  availableFunds.value += revenue

  // 计算本次卖出的实现收益
  const sellProfit = (currentPrice.value - costBasis.value) * tempAmount
  realizedProfit.value += sellProfit

  holdings.value -= tempAmount
  totalSellAmount.value += revenue

  // 更新成本基础
  if (holdings.value === 0) {
    costBasis.value = 0
    totalBuyAmount.value = 0
    totalSellAmount.value = 0
  } else {
    costBasis.value = (totalBuyAmount.value - totalSellAmount.value) / holdings.value
  }

  addTradeRecord('sell', tempAmount, currentPrice.value)
}

// 切换主题
const toggleTheme = () => {
  isDarkMode.value = !isDarkMode.value
  document.documentElement.setAttribute('data-theme', isDarkMode.value ? 'dark' : 'light')
}

const autoStepTimer = ref<number | null>(null)
const autoStepDelay = ref<number | null>(null)

const startAutoStep = () => {
  // 先清除可能存在的定时器
  if (autoStepTimer.value) {
    clearInterval(autoStepTimer.value)
  }
  if (autoStepDelay.value) {
    clearTimeout(autoStepDelay.value)
  }

  // 设置1秒后开始自动执行
  autoStepDelay.value = window.setTimeout(() => {
    autoStepTimer.value = window.setInterval(() => {
      if (canNextStep.value) {
        nextStep()
      } else {
        stopAutoStep()
      }
    }, 200) // 每0.2秒执行一次
  }, 500) // 1秒后开始
}

const stopAutoStep = () => {
  if (autoStepTimer.value) {
    clearInterval(autoStepTimer.value)
    autoStepTimer.value = null
  }
  if (autoStepDelay.value) {
    clearTimeout(autoStepDelay.value)
    autoStepDelay.value = null
  }
}

onMounted(() => {
  trainingStartTime.value = new Date()
  initStockData()
})
</script>

<style scoped>
.stock-training {
  width: 100%;
  min-height: 100vh;
  padding: 4px;
  background: linear-gradient(135deg, #f6f8fc 0%, #f0f2f5 100%);
}

.main-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 auto;
  max-width: 1920px;
}

.title-header {
  background: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.title-header h2 {
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.title-header .subtitle {
  color: #666;
  font-size: 14px;
  margin-top: 4px;
}

.content-section {
  display: flex;
  gap: 14px;
  min-width: 1200px;
  height: 627px;
}

.left-section {
  flex: 1;
  min-width: 900px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: 100%;
  padding: 0;
}

.right-section {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  background: #fff;
  padding: 2px 2px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stock-info {
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stock-info h2 {
  margin: 0;
  color: #1890ff;
  font-size: 20px;
  font-weight: 500;
}

.stock-info .subtitle {
  color: #666;
  font-size: 14px;
}

.price-info {
  margin-top: 3px;
  display: flex;
  align-items: baseline;
  gap: 9px;
}

.price-info span {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.up {
  color: #ef232a !important;
}

.down {
  color: #14b143 !important;
}

.price-info .change {
  font-size: 14px;
}

.chart-container {
  width: 100%;
  height: 100%;
  padding: 0;
  min-height: 585px;
}

.account-info {
  background: #fff;
  padding: 7px 9px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 11px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-item .label {
  color: #666;
  font-size: 13px;
}

.info-item .value {
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.info-item .profit-rate {
  font-size: 13px;
  font-weight: normal;
}

.trading-panel {
  background: #fff;
  padding: 11px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.trading-panel h3 {
  margin: 0 0 16px;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input-wrapper {
  position: relative;
}

.input-wrapper label {
  display: block;
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
}

.input-wrapper input {
  width: 100%;
  padding: 11px;
  padding-right: 90px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.3s;
}

.input-wrapper input:focus {
  outline: none;
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.max-hint {
  position: absolute;
  right: 8px;
  top: calc(50% + 13px);
  transform: translateY(-50%);
  color: #666;
  font-size: 11px;
  pointer-events: none;
}

.button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

button {
  width: 100%;
  padding: 11px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
}

button span {
  font-size: 14px;
  font-weight: 500;
}

button small {
  font-size: 11px;
  opacity: 0.9;
}

.buy-btn {
  background: #ef232a;
  color: white;
}

.buy-btn:hover:not(:disabled) {
  background: #ff4d4f;
}

.sell-btn {
  background: #14b143;
  color: white;
}

.sell-btn:hover:not(:disabled) {
  background: #52c41a;
}

.progress-panel {
  background: #fff;
  padding: 7px 9px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.progress-info {
  margin-bottom: 14px;
}

.progress-text {
  display: flex;
  justify-content: space-between;
  color: #666;
  margin-bottom: 7px;
}

.progress-bar {
  height: 5px;
  background: #f0f0f0;
  border-radius: 3px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #1890ff;
  transition: width 0.3s ease;
}

.next-btn {
  background: #1890ff;
  color: white;
}

.next-btn:hover:not(:disabled) {
  background: #40a9ff;
}

.change-stock {
  background: #722ed1;
  color: white;
  transition: all 0.3s ease;
}

.change-stock:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button:disabled {
  background: #ccc !important;
  cursor: not-allowed;
  opacity: 0.7;
}

@media (max-width: 1400px) {
  .content-section {
    min-width: 1100px;
  }

  .left-section {
    min-width: 800px;
  }
}

.input-wrapper input[type='number'] {
  -moz-appearance: textfield;
}

.input-wrapper input[type='number']::-webkit-outer-spin-button,
.input-wrapper input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.trade-history {
  width: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.trade-history h3 {
  margin: 0 0 12px;
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade-history h3 .summary {
  font-size: 16px;
  color: #666;
  font-weight: 500;
}

.history-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.history-item {
  padding: 20px;
  border-radius: 12px;
  background: #f8f9fa;
  transition: all 0.3s ease;
  border: 1px solid #e6e8eb;
}

.history-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.history-item.buy {
  border-top: 4px solid #ff4d4f;
}

.history-item.sell {
  border-top: 4px solid #52c41a;
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.trade-type {
  font-size: 16px;
  font-weight: 600;
}

.history-item.buy .trade-type {
  color: #ff4d4f;
}

.history-item.sell .trade-type {
  color: #52c41a;
}

.trade-time {
  color: #8c8c8c;
  font-size: 14px;
}

.history-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detail-item .label {
  color: #8c8c8c;
  font-size: 13px;
}

.detail-item .value {
  color: #1a1a1a;
  font-size: 15px;
  font-weight: 500;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #fff;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1890ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

.loading-text {
  color: #666;
  font-size: 16px;
}

.value.loading {
  color: #999;
  font-style: italic;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.quick-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 8px;
}

.quick-btn {
  padding: 8px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  color: white;
}

.quick-btn.buy-all {
  background: rgba(239, 35, 42, 0.8);
}

.quick-btn.buy-all:hover:not(:disabled) {
  background: #ef232a;
}

.quick-btn.sell-all {
  background: rgba(20, 177, 67, 0.8);
}

.quick-btn.sell-all:hover:not(:disabled) {
  background: #14b143;
}

/* 添加浮动按钮样式 */
.float-button {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, #6366f1 0%, #4f46e5 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(99, 102, 241, 0.3);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.float-button:hover {
  transform: translateY(-50%) scale(1.1);
  background: linear-gradient(135deg, #818cf8 0%, #6366f1 100%);
  box-shadow: 0 6px 24px rgba(99, 102, 241, 0.4);
}

.float-button .icon {
  font-size: 20px;
  color: white;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.qr-popup {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  z-index: 1001;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
}

.qr-popup img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
}

.qr-popup::after {
  content: '关注公众号';
  display: block;
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

/* 添加主题切换按钮样式 */
.float-button.theme-toggle {
  top: calc(50% + 60px);
  background: linear-gradient(135deg, #333333 0%, #000000 100%);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.float-button.theme-toggle:hover {
  transform: translateY(-50%) scale(1.1);
  background: linear-gradient(135deg, #666666 0%, #333333 100%);
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.4);
}

.float-button.theme-toggle:hover::after {
  content: '切换主题';
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

[data-theme='dark'] .float-button.theme-toggle:hover::after {
  background: #2d2d2d;
  color: #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.float-button.theme-toggle .icon {
  transition: all 0.3s ease;
  color: white;
}

.float-button.theme-toggle .icon.dark {
  opacity: 0.6;
  filter: brightness(0.8);
  color: white;
}

/* 暗黑主题样式 */
[data-theme='dark'] {
  color: #e0e0e0;
  background: #1a1a1a;
}

[data-theme='dark'] body {
  background: #1a1a1a;
}

[data-theme='dark'] .stock-training {
  background: #1a1a1a;
}

[data-theme='dark'] .title-header,
[data-theme='dark'] .account-info,
[data-theme='dark'] .trading-panel,
[data-theme='dark'] .progress-panel,
[data-theme='dark'] .trade-history,
[data-theme='dark'] .left-section,
[data-theme='dark'] .header {
  background: #2d2d2d;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

[data-theme='dark'] .title-header h2,
[data-theme='dark'] .title-header .subtitle,
[data-theme='dark'] .info-item .label,
[data-theme='dark'] .info-item .value,
[data-theme='dark'] .input-wrapper label,
[data-theme='dark'] .trade-history h3,
[data-theme='dark'] .history-item .label,
[data-theme='dark'] .history-item .value,
[data-theme='dark'] .progress-text,
[data-theme='dark'] .trade-history h3 .summary,
[data-theme='dark'] .trade-time,
[data-theme='dark'] .stock-info h2 {
  color: #e0e0e0;
}

[data-theme='dark'] .info-item .label,
[data-theme='dark'] .trade-history h3 .summary,
[data-theme='dark'] .trade-time,
[data-theme='dark'] .max-hint {
  color: #a0a0a0;
}

[data-theme='dark'] .input-wrapper input {
  background: #1a1a1a;
  border-color: #404040;
  color: #e0e0e0;
}

[data-theme='dark'] .input-wrapper input:focus {
  border-color: #f59e0b;
  box-shadow: 0 0 0 2px rgba(245, 158, 11, 0.1);
}

[data-theme='dark'] .history-item {
  background: #2d2d2d;
  border-color: #404040;
}

[data-theme='dark'] .loading-container {
  background: #2d2d2d;
}

[data-theme='dark'] .loading-text {
  color: #a0a0a0;
}

[data-theme='dark'] .value.loading {
  color: #a0a0a0;
}

[data-theme='dark'] .progress-bar {
  background: #404040;
}

[data-theme='dark'] .progress-fill {
  background: #f59e0b;
}

[data-theme='dark'] .qr-popup {
  background: #2d2d2d;
  border-color: #404040;
}

[data-theme='dark'] .qr-popup::after {
  color: #e0e0e0;
}

[data-theme='dark'] .history-item.buy {
  border-top: 4px solid rgba(239, 35, 42, 0.8);
}

[data-theme='dark'] .history-item.sell {
  border-top: 4px solid rgba(20, 177, 67, 0.8);
}

[data-theme='dark'] .buy-btn {
  background: rgba(239, 35, 42, 0.9);
}

[data-theme='dark'] .buy-btn:hover:not(:disabled) {
  background: rgba(239, 35, 42, 1);
}

[data-theme='dark'] .sell-btn {
  background: rgba(20, 177, 67, 0.9);
}

[data-theme='dark'] .sell-btn:hover:not(:disabled) {
  background: rgba(20, 177, 67, 1);
}

[data-theme='dark'] .next-btn {
  background: rgba(245, 158, 11, 0.9);
}

[data-theme='dark'] .next-btn:hover:not(:disabled) {
  background: rgba(245, 158, 11, 1);
}

[data-theme='dark'] .change-stock {
  background: rgba(124, 58, 237, 0.9);
}

[data-theme='dark'] .change-stock:hover:not(:disabled) {
  background: rgba(124, 58, 237, 1);
}

[data-theme='dark'] .quick-btn.buy-all {
  background: rgba(239, 35, 42, 0.7);
}

[data-theme='dark'] .quick-btn.buy-all:hover:not(:disabled) {
  background: rgba(239, 35, 42, 0.9);
}

[data-theme='dark'] .quick-btn.sell-all {
  background: rgba(20, 177, 67, 0.7);
}

[data-theme='dark'] .quick-btn.sell-all:hover:not(:disabled) {
  background: rgba(20, 177, 67, 0.9);
}

[data-theme='dark'] button:disabled {
  background: #404040 !important;
  opacity: 0.5;
}

[data-theme='dark'] .up {
  color: rgba(239, 35, 42, 1) !important;
}

[data-theme='dark'] .down {
  color: rgba(20, 177, 67, 1) !important;
}

[data-theme='dark'] .tooltip {
  background: #2d2d2d;
  border-color: #404040;
  color: #e0e0e0;
}

/* 添加暗色主题样式 */
[data-theme='dark'] .stock-info {
  background: #2d2d2d;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

[data-theme='dark'] .stock-info h2 {
  color: #1890ff;
}

[data-theme='dark'] .price-info span {
  color: #e0e0e0;
}

[data-theme='dark'] .up {
  color: rgba(239, 35, 42, 1) !important;
}

[data-theme='dark'] .down {
  color: rgba(20, 177, 67, 1) !important;
}

.float-button.reward {
  top: calc(50% + 120px);
  background: linear-gradient(135deg, #ffd700 0%, #ffc107 100%);
  box-shadow: 0 4px 20px rgba(255, 215, 0, 0.3);
}

.float-button.reward:hover {
  transform: translateY(-50%) scale(1.1);
  background: linear-gradient(135deg, #ffeb3b 0%, #ffd700 100%);
  box-shadow: 0 6px 24px rgba(255, 215, 0, 0.4);
}

.reward-text {
  margin-top: 8px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

[data-theme='dark'] .reward-text {
  color: #e0e0e0;
}

/* 打赏按钮独立样式 */
.reward-popup {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  z-index: 1001;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
}

.reward-popup img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
}

[data-theme='dark'] .reward-popup {
  background: #2d2d2d;
  border-color: #404040;
}
</style>
