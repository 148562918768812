<template>
  <div ref="chartRef" class="kline-chart"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import * as echarts from 'echarts'
import type { KLineData } from '../utils/stockData'
import { getThemeColors } from '../styles/theme'

const props = defineProps<{
  data: KLineData[]
  currentIndex: number
  showRealTime?: boolean
  tradeMarkers?: {
    time: number
    type: 'buy' | 'sell'
    price: number
    amount: number
  }[]
  costPrice?: number
}>()

const chartRef = ref<HTMLElement>()
let chart: echarts.ECharts | null = null

const initChart = () => {
  if (!chartRef.value) return

  chart = echarts.init(chartRef.value)
  updateChart()
}

// 计算EMA
const calculateEMA = (data: number[], period: number) => {
  const k = 2 / (period + 1)
  const ema = []
  let emaPrev = data[0]

  for (let i = 0; i < data.length; i++) {
    if (i === 0) {
      ema.push(emaPrev)
    } else {
      emaPrev = data[i] * k + emaPrev * (1 - k)
      ema.push(emaPrev)
    }
  }
  return ema
}

// 计算MACD
const calculateMACD = (data: KLineData[]) => {
  const closeData = data.map((item) => item.close)
  const ema12 = calculateEMA(closeData, 12)
  const ema26 = calculateEMA(closeData, 26)

  // 计算DIF
  const dif = ema12.map((value, index) => value - ema26[index])

  // 计算DEA
  const dea = calculateEMA(dif, 9)

  // 计算MACD柱状图
  const macd = dif.map((value, index) => (value - dea[index]) * 2)

  return { dif, dea, macd }
}

// 计算MA
const calculateMA = (data: KLineData[], period: number) => {
  const result = []
  for (let i = 0; i < data.length; i++) {
    if (i < period - 1) {
      result.push(null)
      continue
    }
    let sum = 0
    for (let j = 0; j < period; j++) {
      sum += data[i - j].close
    }
    result.push(Number((sum / period).toFixed(2)))
  }
  return result
}

// 计算成交量MA
const calculateVolumeMA = (data: KLineData[], period: number) => {
  const result = []
  for (let i = 0; i < data.length; i++) {
    if (i < period - 1) {
      result.push(null)
      continue
    }
    let sum = 0
    for (let j = 0; j < period; j++) {
      sum += data[i - j].volume
    }
    result.push(Number((sum / period).toFixed(2)))
  }
  return result
}

const formatTime = (timestamp: number): string => {
  if (!props.showRealTime) {
    return `${500 - props.data.findIndex((item) => item.time === timestamp)}`
  }
  const date = new Date(timestamp)
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

interface MarkPoint {
  name: string
  coord: [number, number]
  symbol: string
  symbolSize: number
  symbolRotate: number
  itemStyle: {
    color: string
  }
  label: {
    show: boolean
    position: string
    distance: number
    formatter: string
    color: string
    fontSize: number
    fontWeight: string
  }
  emphasis?: {
    label: {
      show: boolean
    }
  }
  type?: string
  lineStyle?: {
    type: string
    color: string
    width: number
    opacity: number
  }
}

const updateChart = () => {
  if (!chart) return

  const theme = getThemeColors()

  const displayData = props.data.slice(0, props.currentIndex + 1)
  const macdData = calculateMACD(displayData)

  // 计算MA
  const ma5 = calculateMA(displayData, 5)
  const ma10 = calculateMA(displayData, 10)
  const ma30 = calculateMA(displayData, 30)
  const ma120 = calculateMA(displayData, 120)

  // 计算成交量MA
  const volumeMa5 = calculateVolumeMA(displayData, 5)
  const volumeMa10 = calculateVolumeMA(displayData, 10)

  // 计算默认显示的K线范围
  const totalBars = displayData.length
  const barsToShow = 100
  const startPercent = Math.max(0, ((totalBars - barsToShow) / totalBars) * 100)
  const endPercent = 100

  // 计算当前显示范围内的最高价和最低价
  const visibleData = displayData.slice(
    Math.floor((totalBars * startPercent) / 100),
    Math.ceil((totalBars * endPercent) / 100),
  )
  const maxPrice = Math.max(...visibleData.map((item) => item.high))
  const minPrice = Math.min(...visibleData.map((item) => item.low))

  // 判断成本线是否在显示范围内
  const isCostPriceVisible =
    props.costPrice && props.costPrice >= minPrice && props.costPrice <= maxPrice

  // 计算标记线的长度（图表高度的1/3）
  const priceRange = maxPrice - minPrice
  const markLineLength = priceRange / 5

  // 生成买卖标记
  const markPoints: MarkPoint[] = []
  if (props.tradeMarkers) {
    props.tradeMarkers.forEach((marker) => {
      const kLineData = displayData[marker.time]
      if (!kLineData) return

      const isBuy = marker.type === 'buy'
      const basePrice = kLineData.close
      const offset = isBuy ? -markLineLength : markLineLength
      const symbolPosition = isBuy ? 'bottom' : 'top'

      // 添加虚线
      markPoints.push({
        name: '',
        coord: [marker.time, basePrice],
        type: 'line',
        symbol: 'none',
        symbolSize: 0,
        symbolRotate: 0,
        itemStyle: {
          color: isBuy ? '#ef232a' : '#14b143',
        },
        label: {
          show: false,
          position: 'top',
          distance: 0,
          formatter: '',
          color: isBuy ? '#ef232a' : '#14b143',
          fontSize: 12,
          fontWeight: 'normal',
        },
        lineStyle: {
          type: 'dashed',
          color: isBuy ? '#ef232a' : '#14b143',
          width: 1,
          opacity: 0.8,
        },
      })

      // 添加买卖标记
      markPoints.push({
        name: isBuy ? '买入' : '卖出',
        coord: [marker.time, basePrice + offset],
        symbol: 'arrow',
        symbolSize: 10,
        symbolRotate: isBuy ? 0 : 180, // 买入箭头向上，卖出箭头向下
        itemStyle: {
          color: isBuy ? '#ef232a' : '#14b143',
        },
        label: {
          show: true,
          position: symbolPosition,
          distance: 5,
          formatter: isBuy ? '买' : '卖',
          color: isBuy ? '#ef232a' : '#14b143',
          fontSize: 12,
          fontWeight: 'bold',
        },
        emphasis: {
          label: {
            show: true,
          },
        },
      })
    })
  }

  const option = {
    backgroundColor: theme.backgroundColor,
    animation: false,
    graphic: [
      {
        type: 'text',
        zlevel: -2,
        left: 'center',
        top: '25%',
        style: {
          text: 'K线演武堂',
          font: 'bold 100px Microsoft YaHei',
          fill: theme.watermarkColor,
          rotate: 45,
        },
      },
      {
        type: 'text',
        left: '3%',
        top: '60%',
        style: {
          text: 'VOL',
          font: '12px Arial',
          fill: theme.textColor,
          fontWeight: 'bold',
        },
      },
      {
        type: 'text',
        left: '3%',
        top: '72%',
        style: {
          text: 'MACD',
          font: '12px Arial',
          fill: theme.textColor,
          fontWeight: 'bold',
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      backgroundColor: theme.tooltipBackground,
      borderWidth: 1,
      borderColor: theme.tooltipBorderColor,
      padding: 10,
      textStyle: {
        color: theme.textColor,
      },
      formatter: (params: any) => {
        // 获取当前K线数据
        const klineData = params[0]?.data || []
        const time = params[0]?.axisValue || ''
        const currentData = props.data[params[0]?.dataIndex]
        const isMainChart = params[0]?.seriesIndex === 0 // 判断是否为主图
        const isVolumeChart = params[0]?.seriesIndex === 5 // 判断是否为成交量图
        const isMacdChart = params[0]?.seriesIndex === 8 // 判断是否为MACD图

        // 构建tooltip内容
        let result = `<div style="font-weight:bold;margin-bottom:5px;color:${theme.textColor};">${time}</div>`

        // 添加K线数据（只在主图显示）
        if (isMainChart && klineData && klineData.length >= 4) {
          result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
            <span style="color:${theme.secondaryTextColor};">开盘：</span>
            <span style="color:${theme.textColor};font-weight:500;">${currentData.open.toFixed(2)}</span>
          </div>`
          result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
            <span style="color:${theme.secondaryTextColor};">收盘：</span>
            <span style="color:${theme.textColor};font-weight:500;">${currentData.close.toFixed(2)}</span>
          </div>`
          result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
            <span style="color:${theme.secondaryTextColor};">最低：</span>
            <span style="color:${theme.textColor};font-weight:500;">${currentData.low.toFixed(2)}</span>
          </div>`
          result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
            <span style="color:${theme.secondaryTextColor};">最高：</span>
            <span style="color:${theme.textColor};font-weight:500;">${currentData.high.toFixed(2)}</span>
          </div>`
          // 添加涨跌幅
          if (currentData?.changePercent !== undefined) {
            const color = currentData.changePercent >= 0 ? '#ef232a' : '#14b143'
            result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
              <span style="color:${theme.secondaryTextColor};">涨跌幅：</span>
              <span style="color:${color};font-weight:500;">${(currentData.changePercent * 100).toFixed(2)}%</span>
            </div>`
          }
        }

        // 添加均线数据（只在主图显示）
        if (isMainChart && params && params.length > 0) {
          params.forEach((param: any) => {
            if (param.seriesName && param.seriesName.startsWith('MA') && param.data !== undefined) {
              result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
                <span style="color:${param.color || theme.secondaryTextColor};">${param.seriesName}：</span>
                <span style="color:${theme.textColor};font-weight:500;">${param.data ? param.data.toFixed(2) : '-'}</span>
              </div>`
            }
          })
        }

        // 添加成交量数据（只在成交量图显示）
        if (isVolumeChart && currentData) {
          result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
            <span style="color:${theme.secondaryTextColor};">成交量：</span>
            <span style="color:${theme.textColor};font-weight:500;">${currentData.volume.toLocaleString()}</span>
          </div>`
          // 添加成交量均线
          params.forEach((param: any) => {
            if (
              param.seriesName &&
              param.seriesName.startsWith('VOL MA') &&
              param.data !== undefined
            ) {
              result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
                <span style="color:${param.color || theme.secondaryTextColor};">${param.seriesName}：</span>
                <span style="color:${theme.textColor};font-weight:500;">${param.data ? param.data.toLocaleString() : '-'}</span>
              </div>`
            }
          })
        }

        // 添加MACD数据（只在MACD图显示）
        if (isMacdChart && params && params.length > 0) {
          params.forEach((param: any) => {
            if (
              param.seriesName &&
              ['DIF', 'DEA', 'MACD'].includes(param.seriesName) &&
              param.data !== undefined
            ) {
              result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
                <span style="color:${param.color || theme.secondaryTextColor};">${param.seriesName}：</span>
                <span style="color:${theme.textColor};font-weight:500;">${param.data ? param.data.toFixed(4) : '-'}</span>
              </div>`
            }
          })
        }

        // 添加成本线数据（只在主图显示）
        if (isMainChart && props.costPrice !== undefined && props.costPrice !== null) {
          result += `<div style="display:flex;justify-content:space-between;margin-bottom:3px;">
            <span style="color:${theme.costLineColor};">成本线：</span>
            <span style="color:${theme.textColor};font-weight:500;">${Number(props.costPrice).toFixed(2)}</span>
          </div>`
        }

        return result
      },
    },
    grid: [
      {
        left: '10%',
        right: '8%',
        top: '5%',
        height: '50%',
        backgroundColor: theme.backgroundColor,
        borderColor: theme.gridColor,
      },
      {
        left: '10%',
        right: '8%',
        top: '60%',
        height: '10%',
        backgroundColor: theme.backgroundColor,
        borderColor: theme.gridColor,
      },
      {
        left: '10%',
        right: '8%',
        top: '75%',
        height: '10%',
        backgroundColor: theme.backgroundColor,
        borderColor: theme.gridColor,
      },
    ],
    xAxis: [
      {
        type: 'category',
        data: displayData.map((item) => formatTime(item.time)),
        axisLine: { lineStyle: { color: theme.axisLineColor } },
        axisTick: { lineStyle: { color: theme.axisLineColor } },
        axisLabel: { color: theme.textColor, show: false },
        splitLine: { show: false },
      },
      {
        type: 'category',
        gridIndex: 1,
        data: displayData.map((item) => formatTime(item.time)),
        axisLine: { lineStyle: { color: theme.axisLineColor } },
        axisTick: { lineStyle: { color: theme.axisLineColor } },
        axisLabel: { color: theme.textColor, show: false },
        splitLine: { show: false },
      },
      {
        type: 'category',
        gridIndex: 2,
        data: displayData.map((item) => formatTime(item.time)),
        axisLine: { lineStyle: { color: theme.axisLineColor } },
        axisTick: { lineStyle: { color: theme.axisLineColor } },
        axisLabel: { color: theme.textColor, show: false },
        splitLine: { show: false },
      },
    ],
    yAxis: [
      {
        scale: true,
        splitNumber: 4,
        axisLine: { lineStyle: { color: theme.axisLineColor } },
        axisTick: { lineStyle: { color: theme.axisLineColor } },
        axisLabel: { color: theme.textColor },
        splitLine: { lineStyle: { color: theme.splitLineColor } },
      },
      {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLine: { lineStyle: { color: theme.axisLineColor } },
        axisTick: { lineStyle: { color: theme.axisLineColor } },
        axisLabel: { show: false },
        splitLine: { lineStyle: { color: theme.splitLineColor } },
      },
      {
        scale: true,
        gridIndex: 2,
        splitNumber: 2,
        axisLine: { lineStyle: { color: theme.axisLineColor } },
        axisTick: { lineStyle: { color: theme.axisLineColor } },
        axisLabel: { color: theme.textColor },
        splitLine: { lineStyle: { color: theme.splitLineColor } },
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0, 1, 2],
        start: startPercent,
        end: endPercent,
        minValueSpan: 20,
        textStyle: { color: theme.textColor },
        borderColor: theme.gridColor,
        backgroundColor: theme.backgroundColor,
        fillerColor: theme.zoomFillColor,
        handleColor: theme.zoomHandleColor,
      },
      {
        show: true,
        xAxisIndex: [0, 1, 2],
        type: 'slider',
        top: '87%',
        start: startPercent,
        end: endPercent,
        minValueSpan: 20,
        textStyle: { color: theme.textColor },
      },
    ],
    series: [
      {
        name: 'K线',
        type: 'candlestick',
        data: displayData.map((item) => [
          Number(item.open.toFixed(2)),
          Number(item.close.toFixed(2)),
          Number(item.low.toFixed(2)),
          Number(item.high.toFixed(2)),
        ]),
        itemStyle: {
          color: theme.upColor,
          color0: theme.downColor,
          borderColor: theme.upColor,
          borderColor0: theme.downColor,
        },
        markPoint: {
          data: markPoints,
          animation: false,
        },
        markLine: {
          data: props.tradeMarkers
            ?.map((marker) => {
              const kLineData = displayData[marker.time]
              if (!kLineData) return null

              const isBuy = marker.type === 'buy'
              const basePrice = kLineData.close
              const offset = isBuy ? -markLineLength : markLineLength

              return [
                {
                  coord: [marker.time, basePrice],
                },
                {
                  coord: [marker.time, basePrice + offset],
                },
              ]
            })
            .filter(Boolean),
          symbol: ['none', 'none'],
          lineStyle: {
            type: 'dashed',
            width: 1,
            opacity: 0.8,
            color: (params: any) => {
              const marker = props.tradeMarkers?.[params.dataIndex]
              return marker?.type === 'buy' ? '#ef232a' : '#14b143'
            },
          },
          animation: false,
        },
      },
      {
        name: 'MA5',
        type: 'line',
        data: ma5,
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.ma5Color },
      },
      {
        name: 'MA10',
        type: 'line',
        data: ma10,
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.ma10Color },
      },
      {
        name: 'MA30',
        type: 'line',
        data: ma30,
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.ma30Color },
      },
      {
        name: 'MA120',
        type: 'line',
        data: ma120,
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.ma120Color },
      },
      {
        name: '成交量',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: displayData.map((item) => ({
          value: Number(item.volume.toFixed(2)),
          itemStyle: {
            color: item.close > item.open ? theme.upColor : theme.downColor,
          },
        })),
      },
      {
        name: 'VOL MA5',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: volumeMa5,
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.ma5Color },
      },
      {
        name: 'VOL MA10',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: volumeMa10,
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.ma10Color },
      },
      {
        name: 'DIF',
        type: 'line',
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: macdData.dif.map((value) => Number(value.toFixed(4))),
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.macdColor },
      },
      {
        name: 'DEA',
        type: 'line',
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: macdData.dea.map((value) => Number(value.toFixed(4))),
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 1, color: theme.macdColor },
      },
      {
        name: 'MACD',
        type: 'bar',
        xAxisIndex: 2,
        yAxisIndex: 2,
        data: macdData.macd.map((value) => Number(value.toFixed(4))),
        itemStyle: {
          color: (params: any) => {
            return params.data >= 0 ? theme.macdUpColor : theme.macdDownColor
          },
        },
      },
      // 添加成本线
      {
        name: '成本线',
        type: 'line',
        data: isCostPriceVisible ? displayData.map(() => props.costPrice) : [],
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 2,
          color: theme.costLineColor,
          type: 'dashed',
        },
        z: 10,
      },
    ],
  }

  chart.setOption(option, true)
}

watch(
  () => props.data,
  () => {
    updateChart()
  },
  { immediate: true },
)

watch(
  () => props.currentIndex,
  () => {
    updateChart()
  },
)

// 添加对 costPrice 的监听
watch(
  () => props.costPrice,
  () => {
    updateChart()
  },
)

onMounted(() => {
  initChart()
  window.addEventListener('resize', () => chart?.resize())

  // 监听主题变化
  const observer = new MutationObserver(() => {
    nextTick(() => {
      updateChart()
    })
  })

  observer.observe(document.documentElement, {
    attributes: true,
    attributeFilter: ['data-theme'],
  })

  onUnmounted(() => {
    observer.disconnect()
    window.removeEventListener('resize', () => chart?.resize())
    chart?.dispose()
  })
})
</script>

<style scoped>
.kline-chart {
  width: 100%;
  height: 100%;
  min-height: 650px;
}

[data-theme='dark'] .kline-chart {
  background: #1a1a1a;
}

.chart-container {
  width: 100%;
  height: 100%;
  padding: 0;
}

.echarts-container {
  width: 100%;
  height: 100%;
  min-height: 720px;
}

[data-theme='dark'] .echarts-container {
  background: #1a1a1a;
}
</style>
